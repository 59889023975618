.MuiDialog-paper {
    /* max-width: 100% !important; */
}

.icon-name {
    position: relative;
    top: -8px;
}

.legends ul {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    list-style-type: none;
    padding: 0px 0px;
    margin: 0px -15px -2px 0px;
}

.legends ul li {
    flex-wrap: wrap;
    margin-right: 15px;
    margin-bottom: 2px;
}

.shape {
    margin-top: 4px;
    margin-right: 10px;
    width: 10px;
    height: 10px
}

.legends ul.parent li {
    width: calc(50% - 15px);
}

.select-tags-card {
    z-index: 999;
    left: 20px;
    top: 20px;
}

.MuiListItemIcon-root {
    min-width: auto !important;
}

.edit-and-delete-tag {
    width: 70px !important;
    font-size: 10px;
    background: white !important;
    color: black;
    height: auto !important;
    border: none !important;
    padding: 5px;
    z-index: 999;
}

.edit-icon {
    width: 15px !important;
    height: 15px !important;
}

.legends .MuiCheckbox-root {
    padding: 5px !important;
}