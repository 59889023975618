.login-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #1976d2;
    /* background: #0090ff !important; */
}

.login-container main {
    padding: 15px;
    background-color: white;
}

.primary-bg {
    /* background-color: #0090ff !important; */
    /* background-color: #1976d2; */
}