.aligner-heading h5 {
    position: relative;
    padding: 0;
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-weight: 300;
    font-size: 20px;
    color: #080808;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
}

.aligner-heading h5 span {
    display: block;
    font-size: 0.5em;
    line-height: 1.3;
}
.aligner-heading h5 {
    font-size: 24px;
    text-align: center;
    line-height: 1.5em;
    padding-bottom: 45px;
    font-family: "Playfair Display", serif;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: #111;
}

.aligner-heading h5:before {
    position: absolute;
    left: 0;
    bottom: 20px;
    width: 60%;
    left: 50%;
    margin-left: -30%;
    height: 1px;
    content: "";
    background-color: #777;
    z-index: 4;
}

.aligner-heading h5:after {
    position: absolute;
    width: 40px;
    height: 40px;
    left: 50%;
    margin-left: -20px;
    bottom: 0px;
    content: '\00a7';
    font-size: 30px;
    line-height: 40px;
    color: #c50000;
    font-weight: 400;
    z-index: 5;
    display: block;
    background-color: #f8f8f8;
}