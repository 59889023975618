/* .basic-card-container .MuiCardHeader-root {
    border-bottom: 1px solid #e3dcdc !important;
    padding: 8px 10px !important;
}

.basic-card-container .MuiCardHeader-root .MuiCardHeader-content span {
    font-size: 15px !important;
} */

.basic-card-container .MuiCardContent-root {
    padding: 3px 5px !important;
}

.basic-card-container .MuiFormControl-root .MuiFormControlLabel-label {
    font-size: 13px !important;
}

.basic-card-container .MuiRadio-root {
    padding: 5px !important;
}

.basic-card-container .MuiFormControlLabel-root {
    margin-left: 0px !important;
}

.select-tooth-table td {
    text-align: center;
    padding: 5px;
    font-size: 12px;
    cursor: pointer;
    border: 1px solid #dddddd;
}