.tags-table table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

.tags-table td,
th {
    border: 1px solid #dddddd !important;
    text-align: left;
    padding: 8px;
}