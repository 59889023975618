#screenshot {
  position: absolute;
  z-index: 1;
}

#draw {
  position: absolute;
  z-index: 2;
  /* pointer-events: none; */
}

.pointers {
  pointer-events: none;
}

#draw #boxes {
  fill: transparent;
  stroke: rgb(95, 186, 221);;
  stroke-width: 3.3;
  fill-opacity: 0.5;
  stroke-opacity: 0.9;
}

#draw #marquee {
  fill: white;
  stroke: red;
  stroke-width: 1;
  stroke-dasharray: 5, 5;
  stroke-opacity: 0.9;
  fill-opacity: 0.2;
}

#draw #marquee.hide {
  display: none;
}

.height-600px {
  height: 600px;
}

.width-500px {
  width: 500px;
}

.prev-arrow {
  top: 50%;
  transform: translateY(-50%);
  left: -51px;
}

.next-arrow {
  top: 50%;
  transform: translateY(-50%);
  left: 40%;
}

.width-20 {
  width: 20% !important;
}

.height-85 {
  height: 85%;
}

.small-img img {
  border: 2px solid #1976d2;
  padding: 3px;
  cursor: pointer;
}

.tag-name {
  z-index: 9;
  color: white;
  background-color: #5fbadd;
  font-size: 16px;
  letter-spacing: 1px;
  user-select: none;
}

/* resizer */
.popup {
  z-index: 9;
  background-color: #5fbadd;
  /* border: 1px solid #d3d3d3;
  text-align: center; */
  /* min-height: 150px;
  min-width: 300px;
  max-height: 300px;
  max-width: 600px; */
}

/*Drgable */

.popup {
  position: absolute;
  /*resize: both; !*enable this to css resize*! */
  /* overflow: auto; */
}

.popup-header {
  /* padding: 10px; */
  cursor: pointer;
  z-index: 10;
  background-color: #2196f3;
  /* color: #fff; */
}

.popup-header::after {
  /* content: "";
  position: absolute;
  border: 1px solid;
  height: 100%;
  transform: rotate(144deg);
  top: 90%;
  left: 30px; */
}

/*Resizeable*/

.popup .resizer-right {
  width: 5px;
  height: 100%;
  background: transparent;
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: e-resize;
  border: none !important;
}

.popup .resizer-bottom {
  width: 100%;
  height: 5px;
  background: transparent;
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: n-resize;
  border: none !important;
}

.popup .resizer-both {
  width: 5px;
  height: 5px;
  background: transparent;
  z-index: 10;
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: nw-resize;
  border: none !important;
}

/*NOSELECT*/

.popup * {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}


.testing {
  position: relative;
  width: 500px;
  height: 500px;
  background-color: red;
  overflow: hidden;
}

.resizable {
  background: white;
  width: 100px;
  height: 100px;
  position: absolute;
  top: 100px;
  left: 100px;
}

.resizable .resizers {
  width: 100%;
  height: 100%;
  border: 3px dotted #4286f4;
  box-sizing: border-box;
}

.resizable .resizers .resizer {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  /*magic to turn square into circle*/
  background: white;
  border: 3px solid #4286f4;
  position: absolute;
}

.resizable .resizers .resizer.top-left {
  left: -5px;
  top: -5px;
  cursor: nwse-resize;
  /*resizer cursor*/
}

.resizable .resizers .resizer.top-right {
  right: -5px;
  top: -5px;
  cursor: nesw-resize;
}

.resizable .resizers .resizer.bottom-left {
  left: -5px;
  bottom: -5px;
  cursor: nesw-resize;
}

.resizable .resizers .resizer.bottom-right {
  right: -5px;
  bottom: -5px;
  cursor: nwse-resize;
}

.dashed {
  background:
    linear-gradient(90deg, blue 50%, transparent 50%),
    linear-gradient(0deg, blue 50%, transparent 50%),
    linear-gradient(90deg, blue 50%, transparent 50%),
    linear-gradient(0deg, blue 50%, transparent 50%);
  background-repeat: repeat-x, repeat-y, repeat-x, repeat-y;
  background-size: 15px 4px, 4px 15px, 15px 4px, 4px 15px;
  background-position: left top, right top, left bottom, left top;
  padding: 4px;
  animation: border-dance 4s infinite linear;
}

@keyframes border-dance {
  0% {
    background-position: left top, right top, right bottom, left bottom;
  }

  100% {
    background-position: right top, right bottom, left bottom, left top;
  }
}