/* Colors css */
.primary-color {
  color: #1976d2;
}
.primary-bg {
  background-color: #1976d2;
}

.primary-bg-important {
  background-color: #1976d2 !important;
}

.secondary-bg {
  background-color: #fd636b !important;
}

.primary-black {
  color: #5d6267 !important;
}

.lc-bg-red {
  background-color: #fd636b;
}
.lc-bg-orange {
  background-color: #ff7a59;
}
.lc-bg-yellow {
  background-color: #ffc107;
}
.lc-bg-green {
  background-color: #2eb670;
}
.lc-bg-blue {
  background-color: #27507e;
}
.lc-bg-primary {
  background-color: #2196f3;
}
.lc-bg-primary-1 {
  background-color: #5fbadd;
}

/* Font size classes */
.font-size-35 {
  font-size: 35px !important;
}

.font-size-10 {
  font-size: 10px;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-13 {
  font-size: 13px !important;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-16 {
  font-size: 16px;
}

/* Font weight classes */
.font-weight-600 {
  font-weight: 600;
}

/* Border-classes */
.gray-border-bottom {
  border-bottom: 1px solid #ebe4e4;
}

.primary-outline {
  outline: 1px solid #1976d2 !important;
}

/* Cursor classes */
.cursor-pointer {
  cursor: pointer;
}
.cursor-move {
  cursor: move;
}

/* Width classes */
.min-width-auto {
  min-width: auto !important;
}

/* Right css classes */
.right-14px {
  right: 14px;
}

.right-0 {
  right: 0;
}
.bottom-0 {
  bottom: 0;
}
.left-0 {
  left: 0;
}

#elem {
  position: absolute;
  width: 100px;
  height: 100px;
  background-color: black;
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  user-select: none;
  cursor: default;
}

.teeth-selected-color {
  background-color: #1976d2 !important;
  color: white;
}

.teeth-selected-color-secondary {
  background-color: #fd636b;
  color: white;
}
